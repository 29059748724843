import React, { useContext } from "react";
import { Switch } from "react-router-dom";
import { OrderTypeNavigation } from "./";
import { RouteWithSubRoutes } from "../../resources/helpers";
import "../../styles/Orders.scss";
import { OrderContext } from "../../contexts/orders";
import SelectedOrderCard from "./SelectedOrderCard";

const Orders = ({ routes }) => {
  const {
    state: { selectedOrder },
  } = useContext(OrderContext);

  return (
    <div id="orders">
      <div className="content">
        <div className="board">
          <Switch>
            {routes.map((route, index) => (
              <RouteWithSubRoutes key={index} {...route} />
            ))}
          </Switch>
        </div>

        <div className="selected-order-container">
          {selectedOrder && <SelectedOrderCard selectedOrder={selectedOrder} />}
        </div>
      </div>

      <OrderTypeNavigation />
    </div>
  );
};

export default Orders;
