import io from "socket.io-client";
import cookies from "js-cookie";
export let socket;

const ENDPOINT =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

export const initiateSocket = (restaurantId) => {
  socket = io(ENDPOINT, {
    auth: {
      token: cookies.get("token"),
    },
  });
  if (socket)
    socket.emit("/restaurants/identify", { restaurantId }, (response) => {
      // TODO: Can retrieve orders with response.orders
    });
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};

export const subscribeToDispatchOrders = (cb) => {
  if (!socket) return true;
  socket.on("/restaurants/order/dispatch", (payload) => {
    return cb(null, payload);
  });
};

export const refreshOrders = (cb, restaurantId) => {
  if (!socket) return true;
  socket.emit("/restaurants/orders/refresh", { restaurantId }, (payload) => {
    return cb(null, payload);
  });
};

export const subscribeToOrdersUpdates = (cb) => {
  if (!socket) return true;
  socket.on("/orders/update", (payload) => {
    return cb(null, payload);
  });
};

export const subscribeToPickupOrders = (cb) => {
  if (!socket) return true;
  socket.on("/restaurants/orders/pickup", (payload) => {
    return cb(null, payload);
  });
};

export const subscribeToCanceledOrders = (cb) => {
  if (!socket) return true;
  socket.on("/orders/cancel", (payload) => {
    return cb(null, payload);
  });
};

export const acceptOrder = (orderId, restaurantId, cb) => {
  if (!socket) return true;
  socket.emit(
    "/restaurants/orders/accept",
    { orderId, restaurantId },
    (payload) => {
      return cb(null, payload);
    }
  );
};

export const endOrderPreparation = (orderId, restaurantId, cb) => {
  if (!socket) return true;
  socket.emit(
    "/restaurants/orders/endPreparation",
    { orderId, restaurantId },
    (payload) => {
      return cb(null, payload);
    }
  );
};

export const handleDisconnection = (callback) => {
  if (!socket) return;
  socket.on("disconnect", (reason) => {
    return callback(reason);
  });
};

export const handleReconnection = ({ restaurantId }, callback) => {
  if (!socket) return;

  socket.io.on("reconnect", (reconnectionAttemptNumber) => {
    socket.emit("/restaurants/identify", { restaurantId }, (response) => {
      callback(reconnectionAttemptNumber);
    });
  })
};
