import { useContext, useMemo } from "react";
import { Button } from "semantic-ui-react";
import { OrderContext } from "contexts/orders";
import OrderRow from "./OrderRow";
import { filterOrders } from "resources/helpers";

const OrdersReady = () => {
  const {
    state: { orders, restaurantId, selectedOrder, unwatchedOrderIds },
  } = useContext(OrderContext);

  const actions = [
    {
      text: "En attente de récupération par le livreur",
    },
  ];

  const readyOrders = useMemo(
    () => filterOrders({ orders, restaurantId, status: "READY" }),
    [orders, restaurantId]
  );

  return (
    <div id="ready-orders">
      {readyOrders.map((order) => (
        <OrderRow
          selectedOrderId={selectedOrder?._id}
          order={order}
          isUnwatched={unwatchedOrderIds.some(
            ({ orderId }) => orderId === order._id
          )}
        >
          {actions && (
            <div className="actions">
              {actions
                .filter(({ visibleOnList }) => visibleOnList)
                .map(({ color, children, onClick, text }, i) => (
                  <Button
                    key={`action-button-${i}`}
                    color={color}
                    size="large"
                    onClick={() => onClick(order)}
                  >
                    {children}
                  </Button>
                ))}
            </div>
          )}
        </OrderRow>
      ))}
    </div>
  );
};

export default OrdersReady;
