import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

export async function loginWithToken() {
  try {
    const { data } = await axios.post("/api/restaurants/login/token");

    return data;
  } catch (err) {
    return null;
  }
}

export async function loginWithCredentials({ username, password }) {
  const { data } = await axios.post("/api/restaurants/login", {
    username,
    password,
  });

  return data;
}

export async function logout() {
  const { data } = await axios.post(
    "/api/restaurants/disconnect?origin=restaurant"
  );

  return data;
}
