import { useContext, useMemo } from "react";
import { OrderContext } from "contexts/orders";
import { Button } from "semantic-ui-react";
import { endOrderPreparation } from "services/socket";
import OrderRow from "./OrderRow";
import { filterOrders } from "resources/helpers";

const OrdersInPreparation = () => {
  const {
    state: { orders, restaurantId, selectedOrder, unwatchedOrderIds },
    dispatch: orderDispatch,
  } = useContext(OrderContext);

  const finishOrder = ({ id }) => {
    endOrderPreparation(id, restaurantId, (err, { order }) => {
      orderDispatch({ type: "UPDATE_ORDER", order });
      orderDispatch({
        type: "DISMISS_SELECTED_ORDER",
      });
    });
  };

  const actions = [
    {
      children: "Terminer",
      onClick: finishOrder,
      color: "green",
    },
    // {
    //   children: "Retarder",
    //   onClick: acceptOrder,
    //   color: "orange",
    // },
  ];

  const preparationOrders = useMemo(
    () => filterOrders({ orders, status: "PREPARATION", restaurantId }),
    [orders, restaurantId]
  );

  return (
    <div id="preparation-orders">
      {preparationOrders.map((order) => (
        <OrderRow
          selectedOrderId={selectedOrder?.id}
          order={order}
          isUnwatched={unwatchedOrderIds.some(
            ({ orderId }) => orderId === order.id
          )}
        >
          {actions && (
            <div className="actions">
              {actions
                .filter(({ visibleOnList }) => visibleOnList)
                .map(({ color, children, onClick, text }, i) => (
                  <Button
                    key={`action-button-${i}`}
                    color={color}
                    size="large"
                    onClick={() => onClick(order)}
                  >
                    {children}
                  </Button>
                ))}
            </div>
          )}
        </OrderRow>
      ))}
    </div>
  );
};

export default OrdersInPreparation;
