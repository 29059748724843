import React, { useReducer, useEffect } from "react";
import { toast } from "react-toastify";
import { fetchOrders } from "services/orders";

let reducer = (state, action) => {
  switch (action.type) {
    case "SET_ORDERS":
      return {
        ...state,
        orders: action.orders,
        preparedProductsMap: setProductsReadiness(
          action.orders,
          state.preparedProductsMap
        ),
      };
    case "REFRESH_ORDERS":
      return refreshOrders(state, action.orders);

    case "UPDATE_ORDER":
      const updatedOrderId = action.order.id;
      const updatedOrderIndex = state.orders.findIndex(
        (order) => order.id === updatedOrderId
      );
      const currentOrdersCopy = [...state.orders];
      if (updatedOrderIndex > -1) {
        currentOrdersCopy[updatedOrderIndex] = action.order;
      } else {
        currentOrdersCopy.push(action.order);
      }

      return {
        ...state,
        orders: currentOrdersCopy,
        selectedOrder: state.selectedOrder
          ? currentOrdersCopy.find(
              (order) => order.id === state.selectedOrder.id
            )
          : null,
      };
    case "ORDERS_LOADING":
      return {
        ...state,
        ordersLoading: true,
      };
    case "ADD_ORDER":
      return {
        ...state,
        orders: [...state.orders, ...[action.order]],
        unwatchedOrderIds: [
          ...state.unwatchedOrderIds,
          { orderId: action.order.id, toastId: action.toastId, isNew: true },
        ],
        preparedProductsMap: setProductsReadiness(
          [action.order],
          state.preparedProductsMap
        ),
      };
    case "REMOVE_ORDER":
      const orderProducts =
        state.orders.find((order) => order.id === action.order.id)
          ?.orderProducts || [];
      const preparedProductsMap = state.preparedProductsMap;
      const orderProductIds = orderProducts.map(
        (orderProduct) => orderProduct.id
      );
      for (const orderProductId of orderProductIds) {
        preparedProductsMap.delete(orderProductId);
      }

      return {
        ...state,
        orders: state.orders.filter((order) => order.id !== action.order.id),
        selectedOrder: null,
        preparedProductsMap,
      };
    case "CANCEL_ORDER":
      return cancelOrder(state, action.order, action.toastId);

    case "ORDERS_LOADING_END":
      return {
        ...state,
        ordersLoading: false,
      };
    case "SET_SELECTED_ORDER":
      removeToast(state.unwatchedOrderIds, action.order.id);

      return {
        ...state,
        selectedOrder:
          state.orders.find((order) => order.id === action.order.id) ||
          action.order,
        unwatchedOrderIds: state.unwatchedOrderIds.filter(
          ({ orderId }) => orderId !== action.order.id
        ),
      };
    case "DISMISS_SELECTED_ORDER":
      return {
        ...state,
        selectedOrder: null,
      };
    case "SET_RESTAURANT_ID":
      return {
        ...state,
        restaurantId: action.restaurantId,
      };
    case "SET_PRODUCT_READY":
      return {
        ...state,
        preparedProductsMap: setProductReady(
          state.preparedProductsMap,
          action.orderProductId
        ),
      };
    default:
      return state;
  }
};

const setProductsReadiness = (orders, preparedProductsMap) => {
  for (const order of orders) {
    for (const orderProduct of order.orderProducts) {
      preparedProductsMap.set(orderProduct.id, {
        quantity: orderProduct.quantity,
        preparedQuantity: 0,
      });
    }
  }

  return preparedProductsMap;
};

const setProductReady = (preparedProductsMap, orderProductId) => {
  if (!preparedProductsMap.has(orderProductId)) {
    return preparedProductsMap;
  }

  const { preparedQuantity, quantity } =
    preparedProductsMap.get(orderProductId);

  if (preparedQuantity < quantity) {
    preparedProductsMap.set(orderProductId, {
      quantity,
      preparedQuantity: preparedQuantity + 1,
    });
  }

  return preparedProductsMap;
};

const refreshOrders = (state, refreshedOrders) => {
  const refreshedOrdersIds = refreshedOrders.map((order) => order.id);
  const currentOrdersIds = state.orders.map((order) => order.id);

  const newOrders = refreshedOrders
    .filter((order) => !currentOrdersIds.includes(order.id))
    .map((order) => order);

  const updatedOrders = state.orders
    .filter((order) => refreshedOrdersIds.includes(order.id))
    .map((order) => refreshedOrders.find(({ id }) => id === order.id));

  return {
    ...state,
    orders: [...updatedOrders, ...newOrders],
    unwatchedOrderIds: [
      ...state.unwatchedOrderIds,
      ...newOrders.map((order) => ({
        orderId: order.id,
        toastId: null,
        isNew: true,
      })),
    ],
    preparedProductsMap: setProductsReadiness(
      newOrders,
      state.preparedProductsMap
    ),
  };
};

const cancelOrder = (state, canceledOrder, toastId) => {
  const ordersCopy = [...state.orders];
  const canceledOrderIndex = ordersCopy.findIndex(
    (order) => order.id === canceledOrder.id
  );

  if (canceledOrderIndex < 0) return state;

  ordersCopy[canceledOrderIndex].isCanceled = true;

  return {
    ...state,
    orders: ordersCopy,
    unwatchedOrderIds: [
      ...state.unwatchedOrderIds,
      { orderId: canceledOrder.id, toastId, isNew: false },
    ],
  };
};

const removeToast = (unwatchedOrderIds, selectedOrderId) => {
  const toastId = unwatchedOrderIds.find(
    ({ orderId }) => orderId === selectedOrderId
  )?.toastId;

  if (toastId) toast.dismiss(toastId);
};

const initialState = {
  restaurantId: null,
  orders: [],
  ordersLoading: true,
  selectedOrder: null,
  unwatchedOrderIds: [],
  preparedProductsMap: new Map(),
};

const OrderContext = React.createContext(initialState);

function OrderProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function initiateOrders() {
    try {
      dispatch({ type: "ORDERS_LOADING" });

      const result = await fetchOrders(state.restaurantId);

      dispatch({ type: "SET_ORDERS", orders: result });
    } catch (err) {
      console.error(err);
    } finally {
      dispatch({ type: "ORDERS_LOADING_END" });
    }
  }

  useEffect(() => {
    if (state.restaurantId) initiateOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.restaurantId]);

  return (
    <OrderContext.Provider value={{ state, dispatch }}>
      {props.children}
    </OrderContext.Provider>
  );
}
export { OrderContext, OrderProvider };
