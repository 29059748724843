import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const useRequest = ({ url, service, defaultParams, requestOnInit = true }) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(requestOnInit);

  const request = async ({ customService, params } = {}) => {
    setError(null);
    setLoading(true);
    let result = null;
    let err = null;
    try {
      result = customService
        ? await customService()
        : // : url
        // ? "TBD" // TODO: handle urls ?
        await service(params || defaultParams);
      setResponse(result);
    } catch (error) {
      err = error.response;
      setError(error.response?.data.error?.comprehensibleMessage || error.response?.data.error?.message);
      toast.error(error.response?.data.error?.comprehensibleMessage || error.response?.data.error?.message)
    } finally {
      setLoading(false);
    }

    return { response: result, err };
  };

  useEffect(() => {
    if (requestOnInit) request();
  }, []);

  return { response, error, loading, request };
};

export default useRequest;
