const hourify = (timeQuantity, initialUnit) => {
  const minutes =
    initialUnit === "milliseconds"
      ? Math.floor(timeQuantity / 1000 / 60)
      : timeQuantity;
  const hours = Math.floor(minutes / 60);
  const restMinutes = minutes % 60;
  return `${hours ? `${hours}h` : ""}${
    restMinutes === 0 && hours > 0 ? "" : `${restMinutes}mn`
  }`;
};

export default hourify;
