import React, { useContext, useMemo } from "react";
import { Button, Label } from "semantic-ui-react";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { OrderContext } from "contexts/orders";
import { Column, Countdown, NoData, Row } from "components/common";
import { OrderProducts } from "components/products";
import { acceptOrder, endOrderPreparation } from "services/socket";
import { getRestaurantOrder } from "resources/helpers";

const SelectedOrderCard = ({ selectedOrder: { id: selectedOrderId } }) => {
  const {
    state: {
      restaurantId,
      orders,
      selectedOrder: currentSelectedOrder,
      preparedProductsMap,
    },
    dispatch: orderDispatch,
  } = useContext(OrderContext);

  const selectedOrder =
    orders.find((order) => order.id === selectedOrderId) ||
    currentSelectedOrder;

  const estimatedPickupAt = useMemo(
    () => getRestaurantOrder(selectedOrder, restaurantId)?.estimatedPickupAt,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOrder]
  );

  let preparedOrderProductCount = 0;
  // if selected order, count prepared products in preparedProductsMap
  const orderProductIds = selectedOrder.orderProducts.map(({ id }) => id);
  for (const orderProductId of orderProductIds) {
    const preparedStatus = preparedProductsMap.get(orderProductId);
    if (preparedStatus) {
      preparedOrderProductCount += preparedStatus.preparedQuantity;
    }
  }

  const handleAccept = ({ id }) => {
    acceptOrder(id, restaurantId, (err, { order }) => {
      orderDispatch({ type: "UPDATE_ORDER", order });
      orderDispatch({
        type: "DISMISS_SELECTED_ORDER",
      });
    });
  };

  const finishOrder = ({ id }) => {
    endOrderPreparation(id, restaurantId, (err, { order }) => {
      orderDispatch({ type: "UPDATE_ORDER", order });
      orderDispatch({
        type: "DISMISS_SELECTED_ORDER",
      });
    });
  };

  const actions = () => {
    const productsCount = selectedOrder.orderProducts.reduce(
      (total, product) =>
        total +
        (restaurantId === product.originalRestaurantId ? product.quantity : 0),
      0
    );

    switch (getRestaurantOrder(selectedOrder, restaurantId)?.status) {
      case "ASSIGNED":
        return (
          <>
            <Button color="green" onClick={() => handleAccept(selectedOrder)}>
              Accepter
            </Button>
            <span className="product-count-indicator">
              <Label size="large" circular color="black">
                {productsCount}
              </Label>{" "}
              produits
            </span>
          </>
        );
      case "PREPARATION":
        return (
          <>
            <Button color="green" onClick={() => finishOrder(selectedOrder)}>
              Prête
            </Button>

            <span className="product-count-indicator">
              <Label
                size="large"
                circular
                color={
                  preparedOrderProductCount === productsCount
                    ? "green"
                    : "black"
                }
              >
                {preparedOrderProductCount}/{productsCount}
              </Label>{" "}
              produits préparés
            </span>
          </>
        );
      case "READY":
        return (
          <Column style={{ alignSelf: "center", marginRight: "1rem" }}>
            <span className="product-count-indicator">
              <Label
                size="large"
                circular
                color={
                  preparedOrderProductCount === productsCount
                    ? "green"
                    : "black"
                }
              >
                {preparedOrderProductCount}/{productsCount}
              </Label>{" "}
              produits préparés
            </span>

            <NoData>En attente de récupération par le livreur</NoData>
          </Column>
        );
      default:
        return null;
    }
  };

  if (!selectedOrder) {
    return null;
  }

  return (
    <div className="selected-order data-container no-padding">
      <Row className="header" justify="space-between" align="center">
        <span className="order-number">{selectedOrder.number}</span>
        {!selectedOrder.readOnly && (
          <span>
            prévue pour{" "}
            <b style={{ fontSize: "1.2rem" }}>
              {dayjs(estimatedPickupAt)
                .locale("fr")
                .format("H:mm")
                .replace(":", "h")}
            </b>
          </span>
        )}
        {selectedOrder.isCanceled ? (
          <div className={`cancel-status`}>ANNULÉE</div>
        ) : !selectedOrder.readOnly ? (
          estimatedPickupAt && <Countdown endAt={estimatedPickupAt} />
        ) : (
          <span>
            récupérée le{" "}
            <b style={{ fontSize: "1.2rem" }}>
              {dayjs(getRestaurantOrder(selectedOrder, restaurantId)?.pickupAt)
                .locale("fr")
                .format("DD/MM/YYYY [à] H:mm")
                .replace(":", "h")}
            </b>
          </span>
        )}

        <Button
          circular
          icon="close"
          onClick={() => orderDispatch({ type: "DISMISS_SELECTED_ORDER" })}
        />
      </Row>

      <div className="content">
        <OrderProducts
          cartProducts={selectedOrder.orderProducts.filter(
            (product) => product.originalRestaurantId === restaurantId
          )}
        />
      </div>

      {
        <Row className="actions" align="center" justify="space-between">
          {selectedOrder.readOnly ? null : !selectedOrder.isCanceled ? (
            actions()
          ) : (
            <Button
              color="blue"
              onClick={() =>
                orderDispatch({ type: "REMOVE_ORDER", order: selectedOrder })
              }
            >
              Masquer
            </Button>
          )}
        </Row>
      }
    </div>
  );
};

export default SelectedOrderCard;
