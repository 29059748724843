import { useContext, useMemo } from "react";
import { Button } from "semantic-ui-react";
import { OrderContext } from "contexts/orders";
import { acceptOrder } from "services/socket";
import OrderRow from "./OrderRow";
import { filterOrders } from "resources/helpers";

const IncomingOrders = () => {
  const {
    state: { orders, restaurantId, selectedOrder, unwatchedOrderIds },
    dispatch: orderDispatch,
  } = useContext(OrderContext);

  const handleAccept = ({ id }) => {
    acceptOrder(id, restaurantId, (err, { order }) => {
      orderDispatch({ type: "UPDATE_ORDER", order });
      orderDispatch({
        type: "DISMISS_SELECTED_ORDER",
      });
    });
  };

  const actions = [
    {
      children: "Accepter",
      onClick: handleAccept,
      color: "green",
      visibleOnList: true,
    },
    // {
    //   children: "Rejeter",
    //   onClick: acceptOrder,
    //   color: "red",
    // },
  ];

  const incomingOrders = useMemo(() => {
    return filterOrders({ orders, restaurantId, status: "ASSIGNED" });
  }, [orders, restaurantId]);

  return (
    <div id="incoming-orders">
      {incomingOrders.map((order) => (
        <OrderRow
          selectedOrderId={selectedOrder?.id}
          order={order}
          isUnwatched={unwatchedOrderIds.some(
            ({ orderId }) => orderId === order.id
          )}
          key={order.id}
        >
          {actions && (
            <div className="actions">
              {actions
                .filter(({ visibleOnList }) => visibleOnList)
                .map(({ color, children, onClick, text }, i) => (
                  <Button
                    key={`action-button-${i}`}
                    color={color}
                    size="large"
                    onClick={(e) => {
                      e.stopPropagation();
                      onClick(order);
                    }}
                  >
                    {children}
                  </Button>
                ))}
            </div>
          )}
        </OrderRow>
      ))}
    </div>
  );
};

export default IncomingOrders;
