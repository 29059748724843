import { useEffect, useState } from "react";
import hourify from "../../resources/helpers/hourify";

const Countdown = ({ endAt }) => {
  const calculateRemainingTime = () => {
    const remainingMilliseconds = new Date(endAt) - new Date();
    return Math.floor(remainingMilliseconds / 1000 / 60);
  };

  const [remaining, setRemaining] = useState(calculateRemainingTime());

  useEffect(() => {
    setRemaining(calculateRemainingTime());
    const interval = setInterval(() => {
      setRemaining(calculateRemainingTime());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [endAt]);

  return (
    <div
      className={`countdown${
        remaining < 10 && remaining > 0
          ? " nearly-late"
          : remaining < 0
          ? " late"
          : ""
      }`}
    >
      {hourify(remaining > 0 ? remaining : -remaining)}
    </div>
  );
};

export default Countdown;
