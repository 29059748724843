import { useContext } from "react";
import { Button } from "semantic-ui-react";
import dayjs from "dayjs";
import { DateInput } from "@blueprintjs/datetime";
import { OrderContext } from "../../contexts/orders";
import OrderRow from "./OrderRow";
import { useRequest } from "../../resources/hooks";
import { fetchCompletedOrders } from "../../services/orders";
import { Column, NoData, Row, WithLoader } from "../common";
import { formatToUppercase } from "../../resources/helpers";
import "dayjs/locale/fr";

const CompletedOrders = () => {
  const {
    state: { selectedOrder },
  } = useContext(OrderContext);
  const getCompletedOrders = useRequest({
    service: fetchCompletedOrders,
    defaultParams: {
      deliveryDate: dayjs().startOf("day").add(12, "hour").toDate(),
    },
  });
  const actions = [
    {
      text: "En attente de récupération par le livreur",
    },
  ];

  const handleDateChange = (deliveryDate) => {
    getCompletedOrders.request({ params: { deliveryDate } });
  };

  return (
    <Column id="completed-orders" childrenMargin="medium">
      <Row
        className="delivery-date-input"
        align="center"
        childrenMargin="small"
      >
        <h3>Date de préparation</h3>
        <DateInput
          formatDate={(date) =>
            formatToUppercase(dayjs(date).locale("fr").format("dddd DD MMMM"))
          }
          parseDate={(str) => new Date(str)}
          fill
          todayButtonText="Aujourd'hui"
          highlightCurrentDay
          placeholder="Aucune"
          showActionsBar
          defaultValue={new Date()}
          onChange={(e) => {
            handleDateChange(dayjs(e).startOf("day").add(12, "hour").toDate());
          }}
        />
      </Row>

      <WithLoader loading={getCompletedOrders.loading}>
        {getCompletedOrders.response?.length > 0 ? (
          getCompletedOrders.response?.map((order) => (
            <OrderRow
              selectedOrderId={selectedOrder?._id}
              order={{ ...order, readOnly: true }}
              hideCountdown
            >
              {actions && (
                <div className="actions">
                  {actions
                    .filter(({ visibleOnList }) => visibleOnList)
                    .map(({ color, children, onClick, text }, i) => (
                      <Button
                        key={`action-button-${i}`}
                        color={color}
                        size="large"
                        onClick={() => onClick(order)}
                      >
                        {children}
                      </Button>
                    ))}
                </div>
              )}
            </OrderRow>
          ))
        ) : (
          <NoData>Aucune commande trouvée pour ce jour</NoData>
        )}
      </WithLoader>
    </Column>
  );
};

export default CompletedOrders;
