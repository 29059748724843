import React, { useState, useEffect, useContext } from "react";
import { useLocation, useRouteMatch, useHistory } from "react-router-dom";
import { OrderContext } from "../../contexts/orders";
import { Ping } from "../common";

const order_types = [
  {
    name: "Nouvelles",
    path: "/incoming",
  },
  {
    name: "En préparation",
    path: "/preparation",
  },
  {
    name: "Prêtes",
    path: "/ready",
  },
  {
    children: null,
    name: "Terminées",
    path: "/completed",
  },
];

const OrderTypeNavigation = () => {
  const [currentOrderType, setCurrentOrderType] = useState(null);
  const {
    state: { unwatchedOrderIds },
  } = useContext(OrderContext);
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  useEffect(() => {
    setCurrentOrderType(
      order_types.find((type) => pathname.includes(type.path))
    );
  }, [pathname]);

  return (
    <div id="order-type-navigation">
      <div className="order-types">
        {order_types.map(({ children, name, path }) => (
          <OrderTypeButton
            key={path}
            path={path}
            active={name === currentOrderType?.name}
            name={name}
            to={`${url}${path}`}
          >
            {path === "/incoming" &&
              unwatchedOrderIds.filter(({ isNew }) => isNew).length > 0 && (
                <Ping
                  inverted={name === currentOrderType?.name}
                  speed="fast"
                  style={{ marginRight: "0.5rem" }}
                />
              )}
            {children} <span>{name}</span>
          </OrderTypeButton>
        ))}
      </div>
    </div>
  );
};

const OrderTypeButton = ({ children, to, active }) => {
  const history = useHistory();
  return (
    <div
      className={`order-type${active ? " active" : ""}`}
      onClick={() => history.push(to)}
    >
      {children}
    </div>
  );
};
export default OrderTypeNavigation;
