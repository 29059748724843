import { useState } from "react";

const useInput = (initialValue, props) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(null);

  return {
    value,
    props: {
      value,
      onChange: (event) => {
        setError(null);
        setValue(event.target.value);
      },
      error,
      ...props,
    },
    setValue: (newValue) => {
      setError(null);
      setValue(newValue);
    },
    setError: (err) => setError(err),
    resetError: () => setError(null),
  };
};

export default useInput;
