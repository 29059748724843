const filterOrders = ({ orders, status, restaurantId }) =>
  orders.filter((order) =>
    order.restaurantOrders.some(
      (restaurantOrder) =>
        restaurantOrder.restaurantId === restaurantId &&
        restaurantOrder.status === status
    )
  );

export default filterOrders;
