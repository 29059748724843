import { useContext } from "react";
import { Label } from "semantic-ui-react";
import { OrderContext } from "contexts/orders";
import { AuthContext } from "contexts/auth";
import { Countdown, Ping, Row } from "components/common";

const OrderRow = ({
  selectedOrderId,
  order,
  children,
  isUnwatched,
  hideCountdown,
}) => {
  const { dispatch: orderDispatch } = useContext(OrderContext);
  const { state } = useContext(AuthContext);
  const isMultiRestaurantOrder = order.restaurantOrders.length > 1;

  function getEstimatedPickupAt() {
    return order.restaurantOrders?.find(
      (restaurantOrder) =>
        restaurantOrder.restaurantId === state?.currentUser?.restaurantId
    )?.estimatedPickupAt;
  }

  return (
    <div
      className={`order-row${selectedOrderId === order.id ? " selected" : ""}${
        order.isCanceled ? " canceled" : ""
      }`}
      highlightOnHover
      onClick={() =>
        selectedOrderId !== order.id
          ? orderDispatch({
              type: "SET_SELECTED_ORDER",
              order,
            })
          : orderDispatch({
              type: "DISMISS_SELECTED_ORDER",
            })
      }
    >
      <Row childrenMargin="small" align="center" fluid>
        <span className="number">{order.number}</span>

        {order.isCanceled ? (
          <div className={`cancel-status`}>ANNULÉE</div>
        ) : (
          getEstimatedPickupAt() &&
          !hideCountdown && <Countdown endAt={getEstimatedPickupAt()} />
        )}

        {isMultiRestaurantOrder && <Label color="blue">Multi-commande</Label>}

        {!order.isCanceled && <div className="action-buttons">{children}</div>}
      </Row>

      {isUnwatched && (
        <Ping
          style={{ alignSelf: "center", marginRight: 0 }}
          className="new-order-ping"
        />
      )}
    </div>
  );
};

export default OrderRow;
