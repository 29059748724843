import React, { useState, useEffect, useContext } from "react";
import { Button, Icon } from "semantic-ui-react";
import {
  Switch,
  Link,
  useLocation,
  Redirect,
  useHistory,
} from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "contexts/auth";
import { OrderContext } from "contexts/orders";
import "styles/RestaurantInterface.scss";
import brandIcon from "resources/png/logo_funky_blanc.png";
import { ReactComponent as BellIcon } from "resources/svg/bell.svg";
// import { ReactComponent as MenuIcon } from "resources/svg/menu.svg";
import {
  Orders,
  IncomingOrders,
  PreparationOrders,
  ReadyOrders,
  CompletedOrders,
} from "./orders";
import { RouteWithSubRoutes, filterOrders } from "resources/helpers";
import {
  socket,
  subscribeToDispatchOrders,
  subscribeToPickupOrders,
  subscribeToCanceledOrders,
  initiateSocket,
  disconnectSocket,
  subscribeToOrdersUpdates,
  refreshOrders,
  handleDisconnection,
  handleReconnection
} from "services/socket";
import { Ping, Row } from "./common";
import NotificationSound from "resources/sounds/notification-sound.mp3";
import {
  INCOMING_SOUND_INTERVAL,
  ORDERS_REFRESH_INTERVAL,
} from "resources/constants";
import { useInterval } from "resources/hooks";
import { logout } from "services/auth";

const orderCreationSound = new Audio(NotificationSound);

const routes = [
  {
    path: "/orders",
    component: Orders,
    icon: (
      <Link to="/orders/incoming">
        <BellIcon />
      </Link>
    ),
    routes: [
      {
        path: "/orders/incoming",
        component: IncomingOrders,
      },
      {
        path: "/orders/preparation",
        component: PreparationOrders,
      },
      {
        path: "/orders/ready",
        component: ReadyOrders,
      },
      {
        path: "/orders/completed",
        component: CompletedOrders,
      },
    ],
  },
  // {
  //   path: "/menu",
  //   component: () => <div>menu</div>,
  //   icon: (
  //     <Link to="/menu">
  //       <MenuIcon />
  //     </Link>
  //   ),
  // },
];

const RestaurantInterface = () => {
  const {
    state: { currentUser },
    dispatch: authDispatch,
  } = useContext(AuthContext);
  const {
    state: { ordersLoading, orders, restaurantId },
    dispatch: orderDispatch,
  } = useContext(OrderContext);
  const [time, setTime] = useState(null);
  const { pathname } = useLocation();
  const { action } = useHistory();

  const [audioLoaded, setAudioLoaded] = useState(false);
  const [audioError, setAudioError] = useState();

  const [displayLogout, setDisplayLogout] = useState(false);

  // const disconnect = async () => {
  //   try {
  //     await logout();
  //     authDispatch({ type: "SET_USER", user: null });
  //   } catch (err) {}
  // };

  const onRefreshOrders = (restaurantId) => {
    refreshOrders((err, { orders }) => {
      orderDispatch({ type: "REFRESH_ORDERS", orders });
    }, restaurantId);
  };

  useInterval(() => {
    const incomingOrders = filterOrders({
      orders,
      restaurantId,
      status: "ASSIGNED",
    });

    if (incomingOrders.length > 0) playNotificationSound();
  }, INCOMING_SOUND_INTERVAL * 1000);

  useInterval(() => {
    setTime(new Date().toLocaleTimeString().slice(0, 5));
  }, 1000);

  useInterval(
    () => onRefreshOrders(restaurantId),
    ORDERS_REFRESH_INTERVAL * 1000
  );

  // Subscribe to socket events
  useEffect(() => {
    initiateSocket(currentUser.restaurantId);
    orderDispatch({
      type: "SET_RESTAURANT_ID",
      restaurantId: currentUser.restaurantId,
    });

    subscribeToDispatchOrders((err, { order }) => {
      if (err) return;

      const toastId = toast(
        <span className="toast-order-creation">
          <Ping />
          <span className="order-number">{order.number}</span>{" "}
          <span className="message">Nouvelle commande</span>
        </span>,
        {
          autoClose: false,
          onClick: () => orderDispatch({ type: "SET_SELECTED_ORDER", order }),
          className: "order-received-toast new",
          closeOnClick: true,
        }
      );

      orderDispatch({ type: "ADD_ORDER", order, toastId });

      playNotificationSound();
    });

    subscribeToOrdersUpdates((err, { order }) => {
      if (err) return;

      const toastId = toast(
        <span className="toast-order-creation">
          <Ping />
          <span className="order-number">{order.number}</span>{" "}
          <span className="message">Commande mise à jour</span>
        </span>,
        {
          autoClose: false,
          onClick: () => orderDispatch({ type: "SET_SELECTED_ORDER", order }),
          className: "order-received-toast update",
          closeOnClick: true,
        }
      );

      orderDispatch({ type: "UPDATE_ORDER", order, toastId });

      playNotificationSound();
    });

    subscribeToPickupOrders((err, { order, pickup }) => {
      if (err) return;

      if (pickup) {
        orderDispatch({ type: "REMOVE_ORDER", order });
      }
    });

    subscribeToCanceledOrders((err, { order }) => {
      if (err) return;

      const toastId = toast(
        <span className="toast-order-creation">
          <Ping />
          <span className="order-number">{order.number}</span>{" "}
          <span className="message">Commande annulée</span>
        </span>,
        {
          autoClose: false,
          onClick: () => orderDispatch({ type: "SET_SELECTED_ORDER", order }),
          className: "order-received-toast cancel",
          closeOnClick: true,
        }
      );

      orderDispatch({ type: "CANCEL_ORDER", order, toastId });

      playNotificationSound();
    });

    handleDisconnection((reason) => {
      console.warn(`Disconnection from socket for reason [${reason}]`);

      toast.warn(
        <div>
          <h4>Perte de connexion au serveur</h4>
          <span>
            Reconnection en cours...
          </span>
        </div>,
        { autoClose: false, toastId: "connectionToastId" }
      )
    });

    handleReconnection(
      { restaurantId: currentUser.restaurantId },
      (reconnectionAttemptCount) => {
        console.warn("reconnected after", reconnectionAttemptCount, "attempts");

        refreshOrders((err, { orders }) => {
          orderDispatch({ type: "REFRESH_ORDERS", orders });
        }, currentUser.restaurantId);

        toast.update("connectionToastId", {
          type: toast.TYPE.SUCCESS,
          render:
            <div>
              <h4>Reconnecté !</h4>
            </div>,
          autoClose: 5000
        })
      }
    );

    return () => {
      disconnectSocket();
    };
  }, []);

  const handleAudioLoad = () => {
    if (audioLoaded) return;

    orderCreationSound.load();
    orderCreationSound.addEventListener("canplaythrough", () => {
      setAudioLoaded(true);
      orderCreationSound.play().catch((e) => {
        setAudioError(e);
      });
    });
  };

  const playNotificationSound = () => {
    setAudioError(undefined);
    orderCreationSound.play().catch((e) => {
      setAudioError(e);
    });
  };

  if (!audioLoaded && action === "POP" && pathname.includes("/orders/")) {
    return <Redirect to="/" />;
  }

  const handleLogout = async () => {
    if (displayLogout) {
      await logout();
      setDisplayLogout(false);
      authDispatch({ type: "SET_USER", user: null });
    } else {
      setDisplayLogout(true);
      setTimeout(() => setDisplayLogout(false), 2000);
    }
  };

  return (
    <div id="restaurant-interface">
      <nav onClick={handleAudioLoad}>
        <Row
          onClick={handleLogout}
          align="center"
          justify="center"
          fluid
          style={{ height: "100px" }}
        >
          {displayLogout ? (
            <Button negative circular icon="log out" size="huge" basic />
          ) : (
            <img src={brandIcon} alt="restos-bussy-icon" />
          )}
        </Row>
        <ul style={{ width: "100%" }}>
          {routes.map(
            ({ icon, path, redirect }) =>
              icon && (
                <li
                  key={path}
                  className={"" + pathname.includes(path) ? "active" : ""}
                  onClick={redirect}
                >
                  <Row align="center" justify="center" fluid>
                    {pathname.includes(path) && (
                      <div className="select-indicator" />
                    )}
                    {icon}

                    {pathname === "/" && (
                      <p className="start-indications">
                        <Icon name="arrow alternate circle left" size="large" />
                        Cliquez sur cette icône pour commencer à recevoir des
                        commandes
                      </p>
                    )}
                  </Row>
                </li>
              )
          )}
        </ul>

        <div className="info">
          {!socket ? (
            <Ping color="#f5bd1f" speed="slow" />
          ) : socket.connected ? (
            <Ping color="#67bc75" speed="slow" />
          ) : (
            <Ping color="#bc6767" speed="slow" />
          )}
          <time
            className="current-time"
            onClick={() => window.location.reload()}
          >
            {time}
          </time>
          <span style={{ textAlign: "center" }}>{currentUser.username}</span>
        </div>
      </nav>

      <div className="content">
        {/* // TODO: loading orders */}

        {/* {audioError && <div>AUDIO ERROR: {audioError.message}</div>} */}
        {ordersLoading ? (
          "Chargement des commandes"
        ) : (
          <Switch>
            {routes.map((route, index) => (
              <RouteWithSubRoutes key={index} {...route} />
            ))}
          </Switch>
        )}
      </div>
      {/* <button onClick={disconnect}>Déconnexion</button> */}
    </div>
  );
};

export default RestaurantInterface;
