import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

export async function fetchOrders(restaurantId) {
  const { data } = await axios.get(`/api/restaurants/${restaurantId}/orders`);

  return data;
}

export async function fetchCompletedOrders({ deliveryDate }) {
  const { data } = await axios.get(`/api/restaurants/orders/completed`, {
    params: {
      deliveryDate,
    },
  });

  return data;
}
