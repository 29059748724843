import React, { useReducer, useEffect } from "react";
import { loginWithToken, logout } from "../services/auth";

let reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        currentUser: action.user,
      };
    case "USER_AUTO_CONNECTING_START":
      return {
        ...state,
        isAutoConnecting: true,
      };
    case "USER_AUTO_CONNECTING_END":
      return { ...state, isAutoConnecting: false };
    case "USER_CONNECT_LOADING":
      return { ...state, isConnecting: true };
    case "USER_CONNECT_END":
      return { ...state, isConnecting: false };
    case "LOG_OUT":
      return handleLogout(state);
    default:
      return state;
  }
};

const handleLogout = async (state) => {
  try {
    await logout();
  } catch (err) {
    console.error(err);
  }

  return {
    ...state,
    currentUser: null,
  };
};

const initialState = {
  currentUser: null,
  isAutoConnecting: true,
  isConnecting: false,
};

const AuthContext = React.createContext(initialState);

function AuthProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function connect() {
    try {
      dispatch({ type: "USER_AUTO_CONNECTING_START" });

      const user = await loginWithToken();

      dispatch({ type: "SET_USER", user });
    } catch (err) {
      console.error(err);
    } finally {
      dispatch({ type: "USER_AUTO_CONNECTING_END" });
    }
  }

  useEffect(() => {
    connect();
  }, []);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AuthContext.Provider>
  );
}
export { AuthContext, AuthProvider, handleLogout };
