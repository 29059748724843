import { useContext } from "react";
import { Icon, Message, Divider } from "semantic-ui-react";
import { OrderContext } from "contexts/orders";
import { Column, Row } from "components/common";

const OrderProducts = ({ cartProducts, children, inverted }) => {
  return (
    <div className={`order-products${inverted ? " inverted" : ""}`}>
      <div className="restaurant-products">
        <Column className="restaurant">
          {cartProducts.map((product, index) => (
            <ProductRow key={`${product._id}-${index}`} product={product} />
          ))}
        </Column>
      </div>

      {children}
    </div>
  );
};

const ProductRow = ({ product }) => {
  const {
    state: { selectedOrder, preparedProductsMap },
    dispatch,
  } = useContext(OrderContext);

  const quantity = product.quantity;
  const preparedQuantity = preparedProductsMap.get(
    product.id
  )?.preparedQuantity;

  const addProductReady = () => {
    if (preparedQuantity < product.quantity) {
      dispatch({
        type: "SET_PRODUCT_READY",
        orderProductId: product.id,
      });
    }
  };

  return (
    <>
      {Array(quantity)
        .fill(product)
        .map((product, index) => (
          <Column className="product" key={`${product.id}-${index}`}>
            <Row align="center" childrenMargin="tiny">
              <div className="count">
                <span>1</span>
                <span style={{ fontSize: "0.7rem", marginLeft: "2px" }}>x</span>
              </div>

              <div className="name">{product.label}</div>

              {!selectedOrder.readOnly && (
                <div
                  className="product-checkbox"
                  align="center"
                  onClick={addProductReady}
                >
                  {index + 1 > preparedQuantity ? null : (
                    <div className="valid-background">
                      <Icon
                        name="check"
                        size="small"
                        style={{ color: "white" }}
                      />
                    </div>
                  )}
                </div>
              )}
            </Row>

            <ProductSpecifics
              options={product.orderProductOptions}
              comments={product.comments}
            />
          </Column>
        ))}
      <Divider />
    </>
  );
};

const ProductSpecifics = ({ options, comments }) => {
  if (options?.length === 0 && !comments) return null;

  return (
    <Column className="item-additionnal-information">
      {options && (
        <Column className="item-options">
          {options.map((option) => (
            <span key={option.id}>
              {option.option}:{" "}
              <span className="option-choices">{option.choice}</span>
            </span>
          ))}
        </Column>
      )}

      {comments && (
        <Message warning icon>
          <Icon name="warning sign" />
          <Message.Content>{comments}</Message.Content>
        </Message>
      )}
    </Column>
  );
};

export default OrderProducts;
