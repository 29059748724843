const Ping = ({ style, className, inverted, speed, color }) => (
  <div
    className={`ping${className ? ` ${className}` : ""}${
      inverted ? " inverted" : ""
    }${speed ? ` ${speed}` : ""}`}
    style={{ ...style, backgroundColor: color }}
  >
    <div className="animated" style={{ ...style, backgroundColor: color }} />
  </div>
);

export default Ping;
